<template>
  <VueCountdown class="" :time="time" v-slot="{ days, hours, minutes, seconds }">
    <div class="countRow">
      <div class="countCol">
        <div class="countDiv">
          {{ days }}
        </div>

        <div v-if="days == 1" class="timeLabel">Dia</div>
        <div v-else class="timeLabel">Dias</div>
      </div>
      <span class="timeLabel" style="margin-top: -32px"> : </span>
      <div class="countCol">
        <div class="countDiv">
          {{ String(hours).padStart(2, "0") }}
        </div>

        <div v-if="hours == 1" class="timeLabel">Hora</div>
        <div v-else class="timeLabel">Horas</div>
      </div>

      <span class="timeLabel" style="margin-top: -32px"> : </span>

      <div class="countCol">
        <div class="countDiv">
          {{ String(minutes).padStart(2, "0") }}
        </div>

        <div v-if="minutes == 1" class="timeLabel">Minuto</div>
        <div v-else class="timeLabel">Minutos</div>
      </div>

      <span class="timeLabel" style="margin-top: -32px"> : </span>

      <div class="countCol">
        <div class="countDiv">
          {{ String(seconds).padStart(2, "0") }}
        </div>

        <div v-if="seconds == 1" class="timeLabel">Segundo</div>
        <div v-else class="timeLabel">Segundos</div>
      </div>
    </div>
  </VueCountdown>
</template>

<script setup>
import { ref, onMounted } from "vue";
import VueCountdown from "@chenfengyuan/vue-countdown";

const time = ref(0);

//function to calculate the time to 1 nov 2024
function calculateTime() {
  const now = new Date();
  const future = new Date("2024-11-01T00:00:00");
  const diff = future - now;
  time.value = diff;
}

onMounted(() => {
  calculateTime();
});
</script>

<style>
.countDiv {
  border: 2px solid #ff73004b;
  border-radius: 4px;
  /* width: 60px; */
  padding: 4px 8px;
  font-size: 4vh;
  display: flex; /* Add this */
  justify-content: center; /* Add this */
  align-items: center; /* Add this */
  background-color: #fff;
  width: 100%;
}

.countRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.countCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff7300;
}

.timeLabel {
  margin-top: 8px;
  color: #000;
  font-size: 18px;
}

@media only screen and (orientation: portrait) {
  /* .timeLabel {
    color: #000;
  } */
}
</style>
